const data = [
  {
    title: "New Product Development Co-op ",
    workplace: "SharkNinja",
    timespan: "Feb. 2023 - present",
    description: [
      "Evaluated designs for two new air purifiers using DFA, DFM metrics and made detailed modifications in prototype to support engineering builds in China ",
      "Led product development of Shark’s various home environment devices by generating test data for R&D team to validate design changes",
      "Worked closely with industrial design and manufacturing groupsto refine and execute vision of the product",
      "Assisted with product design development plans and documented action items to inform factories in Asia for high volume manufacturing",
      "Engaged in discussion between departments on marketing, cost control and functionality of products",
    ],
  },
  {
    title: "Mechanical Engineer Co-op ",
    workplace: "Desktop Metal",
    timespan: "Jun. 2022 - Dec 2022",
    description: [
      "Implemented concepts and followed through initial prototype and testing of vacuum chamber, print head gantry and oxygen sensor for future production",
      "Co-led multiple drawing reviews using SOLIDWORKS and Salesforce propel data management",
      "Designed and constructed test rig for production setup to conduct static electricity generation experiment",
      "Built parts to specification for prototype using machine tools and 3D printers"
    ],
  },
  {
    title: "Graduate Student Technician",
    workplace: "Boston University",
    timespan: "Jan. 2022 - May 2022",
    description: [
      "Teaching Assistant for Automated Design and Manufacturing Labs",
      "Hands on experience with Haas Mills, Gibbs CAM and Universal Robots",
      "Operating CNC machine to demonstrate various machining process",
    ],
  },
  {
    title: "Mechanical Engineer Intern",
    workplace: "Chougule Steels",
    timespan: "May 2021 – Sept. 2021",
    description: [
      "Maintained furnaces of 1500 kg and 500 kg capacities used for sand casting of heavy parts",
      "Lead two CAD design projects in production improvement of contract machinery",
      "Performed analysis on furnace coolant monitoring system used for quality control during the melting process",
    ],
  },
  {
    title: "Product Design Engineer ",
    workplace: "Wings44",
    timespan: "May 2020 – May 2021",
    description: [
      "Collaborated with technicians and senior engineers to ensure DFM and product performance",
      "Planned and developed multiple industrial products using solidworks and ergonomic studies",
      "Programed CNC Laser for precision cutting sheet metal and reducing finishing requirements",
      "Worked in optimizing hospital functionality by designing ICU beds",
    ],
  },
];

export default data;
